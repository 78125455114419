"use strict"
//console.log('js done');

import $ from 'jquery';
import 'owl.carousel2';
require('./library/@fancyapps/fancybox');
import AOS from 'aos';

import pParadigmRevealer from '../vendor/paradigm_modules/paradigm-ui-revealer/paradigm-ui-revealer';

$(document).ready(function () {
    //-------------------------------------------------
    //Old browser detect and redirect

    let $buoop = {required:{e:-4,f:-3,o:-3,s:-1,c:-3},insecure:true,mobile:true,api:2019.02 };
    function $buo_f(){
        let e = document.createElement("script");
        e.src = "//browser-update.org/update.min.js";
        document.body.appendChild(e);
    };
    try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
    catch(e){window.attachEvent("onload", $buo_f)}



    //-------------------------------------------------
    //Nav scroll detect

    // let lastScrollTop = 0;
    // $(window).scroll(function(event){
    //     let st = $(this).scrollTop();
    //     if ((st > lastScrollTop) && (st > 100)){
    //         //console.log("down")
    //         $('.nav_mainMenu').addClass('nav_mainMenu--hidden');
    //         $('.page__mainMenu').addClass('page__mainMenu--hidden');
    //     }
    //     else if(st == lastScrollTop)
    //     {
    //         //do nothing
    //         //In IE this is an important condition because there seems to be some instances where the last scrollTop is equal to the new one
    //     }
    //     else {
    //         //console.log("up")
    //         $('.nav_mainMenu').removeClass('nav_mainMenu--hidden');
    //         $('.page__mainMenu').removeClass('page__mainMenu--hidden');
    //         $('.page__mainMenu').addClass('page__mainMenu--dark');
    //     }
    //     lastScrollTop = st;

    //     if ($(this).scrollTop() < 100) {
    //         $('.page__mainMenu').removeClass('page__mainMenu--dark');
    //     }
    // });


    //-------------------------------------------------
    //Carousels

    $(".section_quoteSlider__carousel").owlCarousel({
        items: 1,
        dots: true,
        nav: true,
        navSpeed: 600,
        autoplay: true,
        autoplayTimeout: 20000,
        autoplayHoverPause: true,
        loop: true,
        navText: ["<span class='owl-arrow-left'></span>", "<span class='owl-arrow-right'></span>"]
    });

    $(".header_workHeader__carousel").owlCarousel({
        items: 1,
        centered:true,
        margin: 40,
        dots: true,
        loop: true,
        nav: true
    });

    $(".section_longRead__slider").owlCarousel({
        items: 1,
        centered:true,
        margin: 40,
        dots: true,
        loop: true
    });

    $('.section_gallery__carousel--mobile').owlCarousel({
        items: 1,
        centered:true,
        margin: 40,
        dots: true,
        loop: false
    });

    $('.section_recentWorks__carousel--mobile').owlCarousel({
        items: 1,
        centered:true,
        margin: 40,
        dots: true,
        loop: false
    });

    var instance = $.fancybox.getInstance();

    $('[data-fancybox]').fancybox({
      wheel: false,
      infobar: false,
      loop: true,
      buttons: [
        "zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        //"thumbs",
        "close"
      ],
      afterClose: function( instance, slide ){
        instance.jumpTo(1, 400);
        //$('.page').css('overflow-x', 'initial');
        //location.reload();
      }
    });

    let galleryslider = $('.section_gallery__carousel--mobile');

    $(document).on('click', '.section_gallery__item', function(event) {
        event.preventDefault();
        galleryslider.trigger('next.owl.carousel');
    });

    let workslider = $('.header_workHeader__carousel');

    // $(document).on('click', '.header_workHeader__item', function(event) {
    //     event.preventDefault();
    //     workslider.trigger('next.owl.carousel');
    // });

    // $(document).on('click', '.header_workHeader__item', function(event) {
    //     event.preventDefault();
    //     workslider.trigger('next.owl.carousel');
    // });

    $().fancybox({
        selector : '.owl-item:not(.cloned) .header_workHeader__item',
        hash: false,
        clickContent : function( current, event ) {
            return current.type === 'image' ? 'zoom' : false;
        },
        onActivate: function( instance, slide) {
            //workslider.trigger('destroy.owl.carousel');
            //console.log('fancybox activated');
        },
        afterClose: function( instance, slide ) {
            $('.header_workHeader__item').css('display', 'block');
            //workslider.trigger('refresh.owl.carousel');
            //console.log('fancybox closed');
        }
    });


    //-------------------------------------------------
    //Mobile Nav animation

    $(document).on('click', '.nav_mainMenu__navIcon', function (event) {
        //event.preventDefault();
        $('.nav_mainMenu__nav').toggleClass('nav_mainMenu__nav--shown');
        $(this).toggleClass('nav_mainMenu__navIcon--close');
    });

    let acc = document.getElementsByClassName("section_job__itemTitle");
    let i, g;

    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function (event) {
            event.preventDefault();
            this.classList.toggle("active");

            let panel = this.nextElementSibling;
            //console.log(panel);

            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                for (g = 0; g < acc.length; g++) {
                    let activeElem = acc[g],
                        contentHeight = activeElem.nextElementSibling.style;

                    if (contentHeight.maxHeight) {
                        // activeElem.classList.toggle("active");
                    }
                    // contentHeight.maxHeight = null;
                }

                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        };
    }


    //-------------------------------------------------
    //Smooth scroll to sections

    $('a[href*="#"]')
    // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 600, function() {
                        // Callback after animation
                        // Must change focus!
                        let $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

    $(document).on('click', '.header_workHeader__videoBlock', function(event) {
        event.preventDefault();
        document.getElementById('videoPlayer').play();
        $('.header_workHeader__play').hide();
        $('.header_workHeader__player').attr('controls', 'true');
    });

    $(document).on('click', '.section_longRead__video', function(event) {
        event.preventDefault();
        document.getElementById('videoPlayer_2').play();
        $('.section_longRead__play').hide();
        $('.section_longRead__player').attr('controls', 'true');
    });

    pParadigmRevealer.show();
    AOS.init();

});
