let pRevealList = [
  'pPage__reveal-1', //backgrounds
  'pPage__reveal-2', //panels
  'pPage__reveal-3', //images
  'pPage__reveal-4', //controls
  'pPage__reveal-5', //text
  'pPage__reveal-6', //reserved
  'pPage__reveal-7', //reserved
  'pPage__reveal-8', //reserved
  'pPage__reveal-9'  //last elements
];

export default pRevealList;