import pRevealList from "../defaults/_pRevealList";

export default class pRevealer {

    constructor () {
        this.UIList = pRevealList;
        this.SectionName = 'pPage__section';
        //console.log('pRevealer.constructor');
    }

    show(timeDelay = 100, timeRandom = 100) {

        //console.log('pRevealer.show');

        //Counter for TimeDelay
        let counter = 0;

        //get the section for right queue animation list
        let UIAnimationList = document.querySelectorAll('.' + this.SectionName);

        // Последовательно перебираем Анимационные секции
        for (let UISection of UIAnimationList) {
            //UISection.classList.add(this.SectionName + '--animate');

            // Перебираем Анимаионные группы друг за другом
            for (let UITag of this.UIList) {
                let ElemetList = UISection.querySelectorAll('.' + UITag);

                // Перебираем элементы в группе
                for (let item of ElemetList) {
                    //item.style.animationPlayState = 'running';
                    setTimeout(() => {
                        item.classList.add(UITag + '--animate');
                        //item.style.animationPlayState = 'running';
                        //console.log(item);
                    }, timeDelay * counter + Math.random() * timeRandom);
                    counter++;
                }
            }
        }
    }
}